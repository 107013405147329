import React, { useState, useRef } from 'react'
import { useSpring, animated, config } from 'react-spring'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import BezierEasing from 'bezier-easing'
import Observer from './observer'

const MaskedImage = ({ fluid, hover }) => {
  const [animationToggle, setAnimationToggle] = useState(false)
  const ref = useRef()

  const maskAnimationWrapper = useSpring({
    from: {
      transform: `translate3d(0, 101%, 0)`
    },
    to: {
      transform: animationToggle ? `translate3d(0, 0%, 0)` : `translate3d(0, 101%, 0)`
    },
    config: {
      duration: 750,
      easing: animationToggle ? BezierEasing(0.1, 0.305, 0.24, 1) : BezierEasing(0.76, 0, 0.9, 0.69)
    },
    delay: animationToggle ? 0 : 500
  })

  const maskAnimationInner = useSpring({
    from: {
      transform: `translate3d(0, -101%, 0)`
    },
    to: {
      transform: animationToggle ? `translate3d(0, 0%, 0)` : `translate3d(0, -101%, 0)`
    },
    config: {
      duration: 750,
      easing: animationToggle ? BezierEasing(0.1, 0.305, 0.24, 1) : BezierEasing(0.76, 0, 0.9, 0.69)
    },
    delay: animationToggle ? 0 : 500
  })

  return (
    <Observer element={ref.current} toggle={animationToggle} setToggle={setAnimationToggle}>
      <ImageContainer
        // style={maskAnimation}
        ref={ref}
      >
        <ImageWrapper style={maskAnimationWrapper}>
          <ImageInner style={maskAnimationInner} hover={hover}>
            <ImageEl fluid={fluid} decoding="sync" />
          </ImageInner>
        </ImageWrapper>
      </ImageContainer>
    </Observer>
  )
}

export default MaskedImage

const ImageContainer = styled(animated.div)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  will-change: transform;
`

const ImageWrapper = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  will-change: transform;
`

const ImageInner = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;

  &:after {
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colours.pink};
    mix-blend-mode: screen;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }

  &:hover:after {
    opacity: ${props => (props.hover ? 0.7 : 0)};
  }
`

const ImageEl = styled(Img)`
  position: absolute;
  height: 100%;
  z-index: 1;

  img {
    object-position: 50% top !important;
  }
`
