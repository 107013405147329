import React, { useState, useEffect, useRef } from 'react'
import { useSpring, animated, config } from 'react-spring'
import styled from 'styled-components'
import BezierEasing from 'bezier-easing'

const MaskedVideo = ({ video, hover }) => {
  const [animationToggle, setAnimationToggle] = useState(false)
  const wrapRef = useRef()
  const videoRef = useRef()

  const maskAnimation = useSpring({
    transform: animationToggle ? `translate3d(0px,0px,0px)` : `translate3d(0,25px,0)`,
    config: config.slow
  })

  const maskAnimationWrapper = useSpring({
    from: {
      transform: `translate3d(0, 101%, 0)`
    },
    to: {
      transform: animationToggle ? `translate3d(0, 0%, 0)` : `translate3d(0, 101%, 0)`
    },
    config: {
      duration: 750,
      easing: animationToggle ? BezierEasing(0.1, 0.305, 0.24, 1) : BezierEasing(0.76, 0, 0.9, 0.69)
    },
    delay: animationToggle ? 0 : 500
  })

  const maskAnimationInner = useSpring({
    from: {
      transform: `translate3d(0, -101%, 0)`
    },
    to: {
      transform: animationToggle ? `translate3d(0, 0%, 0)` : `translate3d(0, -101%, 0)`
    },
    config: {
      duration: 750,
      easing: animationToggle ? BezierEasing(0.1, 0.305, 0.24, 1) : BezierEasing(0.76, 0, 0.9, 0.69)
    },
    delay: animationToggle ? 0 : 500
  })

  useEffect(() => {
    // Init a new observer
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            setAnimationToggle(true)
            if (videoRef.current) {
              videoRef.current.play()
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (videoRef.current) {
              videoRef.current.pause()
            }
          }
        })
      },
      {
        threshold: [0, 0.25, 0.5, 0.75, 1]
      }
    )

    if (videoRef) {
      observer.observe(wrapRef.current)
    }
  }, [])

  return (
    <>
      <VideoContainer style={maskAnimation} ref={wrapRef}>
        <VideoWrapper style={maskAnimationWrapper}>
          <VideoInner style={maskAnimationInner} hover={hover}>
            <Video muted playsInline loop ref={videoRef}>
              <source src={video} type="video/mp4" />
            </Video>
          </VideoInner>
        </VideoWrapper>
      </VideoContainer>
    </>
  )
}

export default MaskedVideo

const VideoContainer = styled(animated.div)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  will-change: transform;
`

const VideoWrapper = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  will-change: transform;
`

const VideoInner = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;

  &:after {
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colours.pink};
    mix-blend-mode: screen;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }

  &:hover:after {
    opacity: ${props => (props.hover ? 0.7 : 0)};
  }
`

const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 1;
`
