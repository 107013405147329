import React, { useState, useContext, useEffect, useRef } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import styled from 'styled-components'
import { useSpring, useTrail, animated } from 'react-spring'
import Observer from '../observer'
import MaskedImage from '../masked-image'
import MaskedVideo from '../masked-video'
import { aspectRatio } from '../../helpers/aspect-ratio'
import { TransitionContext } from '../context/transition-context'

import Triangle from '../../images/filter_triangle.svg'

export const fragment = graphql`
  fragment WorkListSelectedFragment on WordPress_Page_Workpagefields {
    workList {
      caseStudies {
        ... on WordPress_Project {
          workFields {
            featuredInfos {
              subtitle
              title
              image {
                sourceUrl
                mediaItemId
                modified
                altText
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imagePortrait {
                sourceUrl
                mediaItemId
                modified
                altText
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              videoSquare {
                mediaItemUrl
              }
              videoPortrait {
                mediaItemUrl
              }
              videoImageFallback {
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageOrVideo
            }
          }
          slug
          categories {
            nodes {
              name
            }
          }
          projectFilters {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
`

const WorkList = ({ projects, projectFilters }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const [on, toggle] = useState(false)
  const [projectsToList, setProjectsToList] = useState(projects)
  const [thisFilter, setFilter] = useState('all')
  const [fadeToggle, setFadeToggle] = useState(false)
  const [animatedContent, setAnimatedContent] = useState(false)
  const [isDesktop, setIsDesktop] = useState()
  const ref = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const animation = useSpring({
    overflow: 'hidden',
    maxHeight: on ? '350px' : '0px'
  })

  const animationTriangle = useSpring({
    transform: on ? 'rotate(180deg)' : 'rotate(0deg)'
  })

  const updateProjectsArray = categoryName => {
    const updatedProjects = projects.filter(project => {
      // Map over each filter and return true for each hit
      const mappedArr = project.projectFilters.edges.map(
        filter => filter.node.name === categoryName
      )
      return mappedArr.find(filter => filter === true)
    })

    setFadeToggle(true)
    setProjectsToList([])
    setFilter(categoryName)
    toggle(false)
    setTimeout(() => {
      setProjectsToList(updatedProjects)
      setFadeToggle(false)
      setProjectsToList(updatedProjects)
    }, 500)
  }

  const fade = useSpring({
    opacity: fadeToggle ? 0 : 1,
    from: { opacity: 0 },
    config: { duration: fadeToggle ? 300 : 0 }
  })

  const allProjects = () => {
    setFadeToggle(true)
    setFilter('all')
    toggle(false)
    setTimeout(() => {
      setProjectsToList(projects)
      setFadeToggle(false)
      setProjectsToList(projects)
    }, 500)
  }

  const filtersAnimation = useTrail(projectFilters.length, {
    opacity: 1,
    x: 0,
    from: { opacity: 0, x: 20 },
    config: {
      mass: 8,
      tension: 2500,
      friction: 400
    },
    delay: animatedContent ? 100 : 50
  })

  return (
    <Outer>
      <Observer element={ref.current} toggle={animatedContent} setToggle={setAnimatedContent}>
        <WorkBlock ref={ref}>
          <WrapFilter>
            <FilterTitle onClick={() => toggle(!on)}>
              Filter
              <animated.span style={animationTriangle}>
                <Triangle />
              </animated.span>
            </FilterTitle>

            <WrapFilterMobile style={animation}>
              <FilterButton
                type="button"
                value="all"
                onClick={allProjects}
                className={thisFilter === 'all' ? 'active' : ''}
              >
                All
              </FilterButton>

              {filtersAnimation.map(({ x, ...rest }, index) => {
                const filter = projectFilters[index]
                return (
                  <FilterButton
                    key={filter.node.name}
                    type="button"
                    value={filter.node.name}
                    onClick={e => {
                      updateProjectsArray(e.target.value)
                    }}
                    className={thisFilter === filter.node.name ? 'active' : ''}
                    style={{
                      ...rest,
                      // eslint-disable-next-line no-shadow
                      transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
                    }}
                  >
                    {filter.node.name}
                  </FilterButton>
                )
              })}
            </WrapFilterMobile>

            <WrapFilterDesktop>
              <FilterButton
                type="button"
                value="all"
                onClick={allProjects}
                className={thisFilter === 'all' ? 'active' : ''}
              >
                All
              </FilterButton>

              {filtersAnimation.map(({ x, ...rest }, index) => {
                const filter = projectFilters[index]
                return (
                  <FilterButton
                    key={filter.node.name}
                    type="button"
                    value={filter.node.name}
                    onClick={e => {
                      updateProjectsArray(e.target.value)
                    }}
                    className={thisFilter === filter.node.name ? 'active' : ''}
                    style={{
                      ...rest,
                      // eslint-disable-next-line no-shadow
                      transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
                    }}
                  >
                    {filter.node.name}
                  </FilterButton>
                )
              })}
            </WrapFilterDesktop>
          </WrapFilter>

          <WorkFeatured style={fade}>
            {projectsToList.map((project, i) => (
              <WorkItem
                key={project.workFields.featuredInfos.title + i}
                to={`/work/${project.slug}`}
                onClick={event => {
                  event.preventDefault()
                  setTransitionActive(true)
                  setTimeout(() => {
                    navigate(`/work/${project.slug}`)
                    setTransitionActive(false)
                  }, 500)
                }}
              >
                <WrapItem>
                  <WrapWorkImage>
                    {project.workFields.featuredInfos.imageOrVideo === 'image' && (
                      <>
                        {isDesktop === true && (
                          <MaskedImage
                            style={{ position: 'absolute' }}
                            fluid={
                              (i === 0 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 3 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 4 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 7 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 8 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 11 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 12 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 15 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 16 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 19 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 20 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 223 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 24 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 27 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 28 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 31 && project.workFields.featuredInfos.imagePortrait)
                                ? project.workFields.featuredInfos.imagePortrait.imageFile
                                    .childImageSharp.fluid
                                : project.workFields.featuredInfos.image.imageFile.childImageSharp.fluid
                            }
                            alt={project.workFields.featuredInfos.image.altText}
                            hover="true"
                          />
                        )}
                        {!isDesktop === true && (
                          <MaskedImage
                            style={{ position: 'absolute' }}
                            fluid={project.workFields.featuredInfos.image.imageFile.childImageSharp.fluid}
                            alt={project.workFields.featuredInfos.image.altText}
                            hover="true"
                          />
                        )}
                      </>
                    )}
                    {project.workFields.featuredInfos.imageOrVideo === 'video' && (
                      <>
                        {isDesktop === true && (
                          <MaskedVideo
                            video={
                              (i === 0 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 3 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 4 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 7 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 8 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 11 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 12 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 15 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 16 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 19 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 20 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 223 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 24 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 27 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 28 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 31 && project.workFields.featuredInfos.videoPortrait)
                                ? project.workFields.featuredInfos.videoPortrait.mediaItemUrl
                                : project.workFields.featuredInfos.videoSquare.mediaItemUrl
                            }
                            hover="true"
                          />
                        )}
                        {!isDesktop === true && (
                          <MaskedImage
                            style={{ position: 'absolute' }}
                            fluid={
                              project.workFields.featuredInfos.videoImageFallback.imageFile
                                .childImageSharp.fluid
                            }
                            alt={project.workFields.featuredInfos.image.altText}
                            hover="true"
                          />
                        )}
                      </>
                    )}
                  </WrapWorkImage>
                  <ItemDesc>
                    {project.categories.nodes.map((cat, i) => (
                      <span key={i}>{cat.name} </span>
                    ))}
                    <h2> {project.workFields.featuredInfos.title} </h2>
                    <p> {project.workFields.featuredInfos.subtitle} </p>
                  </ItemDesc>
                </WrapItem>
              </WorkItem>
            ))}
          </WorkFeatured>
        </WorkBlock>
      </Observer>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  transform: translateY(-10vh);
  min-height: 150vh;

  @media (max-width: 1024px) {
    transform: translateY(0);
  }
`

const WorkBlock = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
`

const WrapFilter = styled.div`
  position: absolute;
  left: 51.25%;
  margin: 0 auto;
  color: ${props => props.theme.colours.pink};
  z-index: 10;

  @media (max-width: 1100px) {
    left: 52.25%;
  }

  @media (max-width: 1024px) {
    position: relative;
    width: 85%;
    margin: 0 auto 5rem auto;
    right: unset;
    left: unset;
  }
`

const FilterButton = styled(animated.button)`
  background-color: transparent;
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 2rem;
  color: ${props => props.theme.colours.pink};
  padding: 10px 15px;
  border: 0;
  margin-right: 1rem;
  margin-bottom: 2rem;
  border: 2px solid transparent;
  cursor: pointer;
  text-transform: capitalize;

  @media (max-width: 1100px) {
    font-size: 1.8rem;
    margin-right: 0;
    padding: 10px 10px;
  }

  @media (max-width: 1024px) {
    display: block;
    font-size: 2.8rem;
    padding: 5px 10px;
  }

  &.active {
    border: 2px solid ${props => props.theme.colours.pink};
  }

  &:focus {
    outline: none;
  }
`

const WrapFilterMobile = styled(animated.div)`
  display: none;

  @media (max-width: 1024px) {
    display: block;
    height: auto;
  }
`

const WrapFilterDesktop = styled.div`
  display: block;

  @media (max-width: 1024px) {
    display: none;
  }
`

const FilterTitle = styled.div`
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.sofia};
  font-weight: 400;
  line-height: 2;
  margin-bottom: 2rem;
  display: none;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    display: block;
    font-size: 2.8rem;
  }

  span {
    line-height: 0;
    display: inline-block;
    margin-left: 1rem;
  }
`

const WorkFeatured = styled(animated.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 80%;
  width: 100%;

  @media (max-width: 650px) {
    max-width: calc(100% - 50px);
  }
`

const WrapWorkImage = styled.div`
  position: relative;
`

const WrapItem = styled.div`
  width: 100%;
  padding: 0 0.5rem;

  @media (max-width: 650px) {
    margin-bottom: 6.5rem;
  }

  &:hover h2 {
    color: ${props => props.theme.colours.pink};
  }
`

const WorkItem = styled(Link)`
  width: calc(50% - 2rem);

  @media (max-width: 769px) {
    width: 47%;
  }

  &:hover h2 {
    color: ${props => props.theme.colours.pink};
  }

  ${WrapWorkImage} {
    ${aspectRatio(537, 469)}
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 0;

    &:nth-child(odd) {
      width: 100%;

      ${WrapWorkImage} {
        width: 100%;
        padding: 0;
        ${aspectRatio(537, 469)}
      }
    }

    &:nth-child(even) {
      width: 100%;

      ${WrapWorkImage} {
        ${aspectRatio(537, 469)}
        width: 100%;
        padding: 0;
      }
    }
  }

  @media (min-width: 650px) {
    &:nth-child(4n + 1) {
      width: 42%;
      padding: 0 1rem;
      margin-bottom: 5rem;

      @media (max-width: 650px) {
        width: 100%;
      }

      ${WrapWorkImage} {
        ${aspectRatio(474, 658)}
      }
    }

    &:nth-child(4n) {
      width: 42%;
      padding: 0 1rem;
      margin-bottom: 5rem;

      @media (max-width: 650px) {
        width: 100%;
      }

      ${WrapWorkImage} {
        ${aspectRatio(474, 658)}
      }
    }
  }

  @media (min-width: 700px) and (max-width: 1024px) {
    &:nth-child(4n + 1) {
      width: 47%;
      padding: 0;
      margin-bottom: 5rem;

      @media (max-width: 769px) {
        width: 47%;
        margin-bottom: 5rem;
      }

      ${WrapWorkImage} {
        ${aspectRatio(474, 658)}
      }
    }

    &:nth-child(4n) {
      width: 47%;
      padding: 0;
      margin-bottom: 5rem;

      @media (max-width: 769px) {
        width: 47%;
        margin-bottom: 5rem;
      }

      ${WrapWorkImage} {
        ${aspectRatio(474, 658)}
      }
    }
  }
`

const ItemDesc = styled.div`
  margin-top: 2.5rem;
  span {
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 700;
    font-size: 1.2rem;
    letter-spacing: 0.22rem;
    text-transform: uppercase;
    margin-right: 1.5rem;
    display: inline-block;
    line-height: 1.75;

    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }

    @media (max-width: 650px) {
      margin-left: 0;
      font-size: 1.75rem;
    }
  }

  h2 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 4.5rem;
    letter-spacing: -0.075rem;
    margin-top: 1rem;
    transition: color 0.25s ease;

    @media (max-width: 650px) {
      margin-top: 2rem;
      font-size: 5rem;
    }
  }

  p {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 300;
    font-size: 1.6rem;

    @media (max-width: 1024px) {
      font-size: 2rem;
    }

    @media (max-width: 650px) {
      margin-left: 0;
      font-size: 2.2rem;
    }
  }
`

export default WorkList
