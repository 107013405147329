import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import WorkListSelected from '../components/work/work-list'
import WorkHero from '../components/work/hero'
import SEO from '../components/seo'

const Work = ({ data, pageContext }) => (
  <Layout>
    <SEO pageTitle={pageContext.title} slug={pageContext.slug} {...data.wordPress.pageBy.seo} />
    <WorkHero hero={data.wordPress.pageBy.workpageFields.hero} />
    <WorkListSelected
      projectFilters={data.wordPress.projectFilters.edges}
      projects={data.wordPress.pageBy.workpageFields.workList.caseStudies}
    />
  </Layout>
)

export default Work

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      pageBy(pageId: $wordpressId) {
        ...SeoFragment
        workpageFields {
          ...WorkpageHeroFragment
          ...WorkListSelectedFragment
        }
      }
      projectFilters {
        edges {
          node {
            name
          }
        }
      }
    }
  }
`
